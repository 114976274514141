import { CourseLabels } from 'core/constants/app-constants';

function extractUserId(email: string, asurite?: string) {
  return asurite || email?.split('@').shift() || '';
}

function parsePlanId(planId: string) {
  return `${planId.replaceAll('PLAN#', '')}`;
}

function parseBoolToYesNo(value?: boolean, defaultResponse = 'No') {
  if (value === undefined || value === null) {
    return defaultResponse;
  }

  if (value) {
    return 'Yes';
  }
  return 'No';
}

/**
 * Convert 5 digits term to 4 digits format
 * @example const term = to4digitsTerm("20217")
 * console.log(term); // 2217
 * @param sTerm
 */
function toTerm4Digits(sTerm: string) {
  if (sTerm.length === 5) {
    return sTerm.slice(0, 1) + sTerm.slice(2);
  }
  return sTerm;
}

function isMaxCreditWarning(
  reason: API.PlanData.PlanValidationError['reason'],
) {
  return (
    reason === 'TERM_CREDIT_HOURS_EXCEED' ||
    reason === 'SESSION_CREDIT_HOURS_EXCEED'
  );
}

function buttonVisibility({
  status,
  isActivePlan,
  courseLabel,
  userChosenCourse,
}: {
  status: API.PlanData.ClassStatus;
  isActivePlan: boolean;
  courseLabel: string | undefined;
  userChosenCourse?: boolean | undefined;
}) {
  let chooseCourseVisible = true;
  let removeCourseVisible = true;
  let saveCourseVisible = true;
  let closeCourseVisible = false;
  let termSessionSelectorReadonly = false;

  if (status === 'planned') {
    // [Active & What-if Plans]
    if (courseLabel === CourseLabels.SINGLE_COURSE_OPTION) {
      chooseCourseVisible = false;
      removeCourseVisible = false;
    } else if (courseLabel === CourseLabels.ELECTIVE) {
      chooseCourseVisible = true;
      removeCourseVisible = true;
    }
  } else if (status === 'in-progress' || status === 'not-started') {
    if (isActivePlan) {
      saveCourseVisible = false;
      termSessionSelectorReadonly = true;
      chooseCourseVisible = false;
      removeCourseVisible = false;
    } else {
      if (courseLabel === CourseLabels.SINGLE_COURSE_OPTION) {
        chooseCourseVisible = false;
        removeCourseVisible = false;
      }
    }
  } /* status === 'completed' */ else {
    chooseCourseVisible = false;
    removeCourseVisible = false;
    saveCourseVisible = false;
    closeCourseVisible = true;
  }

  if (userChosenCourse) {
    removeCourseVisible = false;
  }
  // handle cases for old plans created prior `courseLabel` BE implementation
  if (!courseLabel) {
    chooseCourseVisible = false;
  }

  return {
    chooseCourseVisible,
    removeCourseVisible,
    saveCourseVisible,
    closeCourseVisible,
    termSessionSelectorReadonly,
  };
}

export {
  buttonVisibility,
  extractUserId,
  isMaxCreditWarning,
  parseBoolToYesNo,
  parsePlanId,
  toTerm4Digits,
};
